'use client';
import { useState } from 'react';
import { StrapiProfileSectionData } from '@/modules/strapiTypes';
import UserDetails from './UserDetails';
import OrderHistory from './OrderHistory';
import DetailHistorySwitch from './DetailHistorySwitch';
import { PageWidth } from '@/utils/strapiUtils';

export function ProfileSection(data: StrapiProfileSectionData) {
  const [displayUserDetails, setDisplayUserDetails] = useState<boolean>(true);

  return (
    <div className="px-default-sm md:px-default">
      <div
        id={data.anchor}
        className="mx-auto max-w-page-width-mx-680"
        style={{ maxWidth: data.page_width && PageWidth[data.page_width] }}
      >
        <DetailHistorySwitch
          displayUserDetails={displayUserDetails}
          setValue={setDisplayUserDetails}
          userDetailsLabel={data.selection_user_detail ?? 'Dina uppgifter'}
          orderHistoryLabel={data.selection_order_history ?? 'Orderhistorik'}
        />
        {displayUserDetails ? (
          <UserDetails {...data} />
        ) : (
          <OrderHistory {...data} />
        )}
      </div>
    </div>
  );
}
