import { Dispatch } from 'react';

interface DetailHistorySwitchProps {
  displayUserDetails: boolean;
  setValue: Dispatch<boolean>;
  userDetailsLabel: string;
  orderHistoryLabel: string;
}

export default function DetailHistorySwitch({
  displayUserDetails,
  setValue,
  userDetailsLabel,
  orderHistoryLabel,
}: DetailHistorySwitchProps) {
  return (
    <div className="mt-20 flex w-full cursor-pointer flex-row rounded-xl border border-hlr-gunmetal text-center font-bold">
      <div
        className={`${
          displayUserDetails ? 'bg-hlr-green text-white' : ''
        } flex w-1/2 justify-around rounded-l-xl py-3`}
        onClick={() => setValue(true)}
      >
        <p className="my-auto">{userDetailsLabel}</p>
      </div>
      <div
        className={`${
          !displayUserDetails ? 'bg-hlr-green text-white' : ''
        } flex w-1/2 justify-around rounded-r-xl py-3`}
        onClick={() => setValue(false)}
      >
        <p className="my-auto">{orderHistoryLabel}</p>
      </div>
    </div>
  );
}
